const services = [
  {
    name: "Aloittaville yrityksille",
    url: "/palvelut/aloittaville-yrityksille",
    activeKey: "1",
  },
  {
    name: "Digiloikkaajille",
    url: "/palvelut/digiloikkaajille",
    activeKey: "2",
  },
  {
    name: "IT-korporaatioille",
    url: "/palvelut/it-korporaatioille",
    activeKey: "3",
  },
];

export default services;
