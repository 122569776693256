/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import SecondaryNavbar from "../../../src/components/SecondaryNavbar";
import services from "../../metadata/services.js";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    em: "em"
  }, _provideComponents(), props.components), {Container} = _components;
  if (!Container) _missingMdxReference("Container", true);
  return React.createElement(Container, null, React.createElement(SecondaryNavbar, {
    title: "Palvelut",
    activeKey: "0",
    links: services
  }), React.createElement(_components.p, null, "Monelle asiantuntijayritykselle tuottaa usein vaikeuksia vastata\nyksiselitteisesti kysymykseen: mitä te myytte? Niin ikään monelle\nasiantuntijayrityksen asiakkaalle, erityisesti juuri IT-palvelujen hankkijalle,\non jopa vaikeampaa vastata siihen toiseen kysymykseen: ", React.createElement(_components.em, null, "mitä te ostatte?")), React.createElement(_components.p, null, "Niinpä me (minä) täällä yhden hengen asiantuntijayrityksessämme keskitymme ihan\nensiksi siihen, mitä asiakas oikeasti tarvitsee."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
