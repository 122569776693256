import { Link } from "gatsby";
import React from "react";
import { Col, Nav, Row } from "react-bootstrap";

const SecondaryNavbar = ({ title, links, activeKey }) => {
  return (
    <>
      <h1 className="mb-5">{title}</h1>
      <Nav activeKey={activeKey} className="d-block text-center">
        <Row className="mt-4 mb-5 border-0 text-center d-flex align-items-center justify-content-center">
          {links &&
            links.map((link, key) => {
              return (
                <Col key={key} sm={12} lg={4}>
                  <Nav.Link
                    as={Link}
                    to={link.url}
                    eventKey="0"
                    className={
                      activeKey === link.activeKey
                        ? "bg-primary shadow text-white"
                        : "bg-black bg-opacity-75 shadow-sm text-white"
                    }
                    style={{
                      textDecoration: "none",
                      fontSize: "22px",
                      border: "none",
                      borderRadius: "0",
                    }}
                  >
                    {link.name}
                  </Nav.Link>
                </Col>
              );
            })}
        </Row>
      </Nav>
    </>
  );
};

export default SecondaryNavbar;
